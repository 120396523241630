import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import { Typography } from "@mui/material";

const CommonSecondSection = ({ image, title, content, bgwhite }) => {
  return (
    <section className={`second-section ${bgwhite}`}>
      <Container fluid className="content___speacing ">
        <Row className="align-items-center">
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className="img-container">
            <img src={image} alt={image} />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="TTP-content-space p-0"
          >
            <div className="TTP-content">
              {title && (
                <Typography className="header-text">{title}</Typography>
              )}
              {content?.map((data, i) => {
                return <Typography className={"para-text"}>{data}</Typography>;
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CommonSecondSection;
