import * as React from "react";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import AddAdminModal from "./AddAdminModal";
import AdminListTable from "./AdminListTable";
import { ROLES } from "../../helpers/constants";
import { adminAction } from "../../../redux/slices/admin/admins/adminSlice";

import "../AdminDashboard/propertyTable.scss";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";
import { useEffect } from "react";
import { Loader } from "../../common/loader";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";

const Admins = () => {
  const dispatch = useDispatch();
  const [isAddAdminModalOpen, setIsAddAdminModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // store data
  const { userProfileDetails } = useSelector((state) => state?.userProfile);
  const { countyRecordersLoader } = useSelector(
    (state) => state.countyRecorders
  );
  // handle addProfessional modal close
  const handleClose = () => {
    dispatch(userProfileAction.setDefaultSelectedTab(1));
    setIsAddAdminModalOpen(false);
  };

  // handle edit professional in add professional modal
  const handleEditAdmin = (item) => {
    setSelectedItem(item);
    setIsAddAdminModalOpen(true);
    if (item?.county_admin_id) {
      dispatch(userProfileAction.setDefaultSelectedTab(2));
    } else {
      dispatch(userProfileAction.setDefaultSelectedTab(1));
    }
  };

  //   handle add/edit save admin
  const handleSubmitAdminForm = (values, source) => {
    if (source === "iternal_admin") {
      console.log("internal admin values", values);

      if (selectedItem && selectedItem?.id) {
        // handle edit dispatch
        dispatch(
          adminAction?.addAdminDetails({
            url: `admin/${selectedItem?.id}/edit`,
            data: values,
            token: true,
          })
        );
      } else {
        // handle add dispatch
        dispatch(
          adminAction?.addAdminDetails({
            url: `admin/create`,
            data: values,
            token: true,
          })
        );
      }
    }

    if (source === "county_admin") {
      console.log("county admin values", values);
      if (selectedItem && selectedItem?.id) {
        // handle edit dispatch
        dispatch(
          adminAction?.addAdminDetails({
            url: `admin/county/edit/user/${selectedItem?.county_admin_id}`,
            data: { ...values, id: selectedItem?.county_admin?.county_id },
            token: true,
          })
        );
      } else {
        dispatch(
          adminAction?.addCountyAdminDetails({
            url: `admin/county/${values?.county}/create/user`,
            data: values,
            token: true,
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(
      countyRecordersAction.registeredCountyList({
        url: `admin/counties`,
        data: {},
        token: true,
      })
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h6" className="title">
          Manage your admin content
        </Typography>
        {userProfileDetails?.data?.role?.includes(ROLES.admin) && (
          <>
            <Button
              variant="contained"
              className="success-btn admin_buttons d-none d-sm-block"
              onClick={() => {
                setSelectedItem(null);
                setIsAddAdminModalOpen(true);
              }}
              style={{
                borderRadius: "20px",
              }}
            >
              Add Admin
            </Button>
            <Button
              className="success-btn admin_buttons d-flex d-sm-none"
              variant="outlined"
              onClick={() => {
                setSelectedItem(null);
                setIsAddAdminModalOpen(true);
              }}
              startIcon={<AddOutlinedIcon />}
              sx={{ color: "#FFF" }}
            >
              Add
            </Button>
          </>
        )}
      </Box>
      <Box>
        <AdminListTable
          handleEditButton={handleEditAdmin}
          handleClose={handleClose}
        />
      </Box>
      <AddAdminModal
        open={isAddAdminModalOpen}
        handleClose={handleClose}
        selectedItem={selectedItem}
        onSubmit={handleSubmitAdminForm}
      />
      <Loader open={countyRecordersLoader} />
    </>
  );
};

export default Admins;
