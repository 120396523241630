import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import { Typography } from "@mui/material";

const CommonThirdSection = ({
  image,
  title,
  content = [],
  reversColumn = false,
}) => {
  return (
    <section className="third-section py-5 bg-white">
      <Container fluid className="content___speacing ">
        <Row
          className={`align-items-center ${reversColumn && "reverse-column"}`}
        >
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="TTP-content-space"
          >
            <div className="TTP-content">
              <Typography className="header-text">{title}</Typography>
              {content?.map((data, i) => {
                return <Typography className={"para-text"}>{data}</Typography>;
              })}
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="img-container img-end"
          >
            <img src={image} alt={image} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CommonThirdSection;
