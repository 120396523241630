import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import { Typography } from "@mui/material";

const CommonFourthSection = ({
  image,
  image_mobile,
  title,
  content = [],
  reversColumn = false,
  listBelow = [],
  lastContent = [],
}) => {
  return (
    <section className="fourth-section">
      <Container fluid className="content___speacing ">
        <Row
          className={`align-items-center ${reversColumn && "reverse-column"}`}
        >
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className="img-container">
            <img
              src={image}
              alt="Man working on laptop"
              className={`bro ${image_mobile && "hide_for_mobile"}`}
            />
            {image_mobile && (
              <img
                src={image_mobile}
                alt="Man working on laptop"
                className="bro bro_mobile"
              />
            )}
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="TTP-content-space p-0"
          >
            <div className="TTP-content">
              <Typography className="header-text">{title}</Typography>
              {content?.map((data, i) => {
                return <Typography className={"para-text"}>{data}</Typography>;
              })}
              {listBelow && (
                <p className="content-list">
                  <ul>
                    {listBelow?.map((data, i) => {
                      return <li className="para-text">{data}</li>;
                    })}
                  </ul>
                </p>
              )}
              {lastContent &&
                lastContent?.map((data, i) => {
                  return (
                    <Typography className={"para-text pt-0"}>{data}</Typography>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CommonFourthSection;
