import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import { Typography } from "@mui/material";

import ProtectionCTASection from "./ProtectionCTASection";

const CommonFifthSection = ({
  image,
  title,
  content = [],
  title_container2,
  content_containet2,
}) => {
  return (
    <section className="fifth-section py-5">
      <Container fluid className="content___speacing ">
        <Row className="align-items-center">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="TTP-content-space"
          >
            <div className="TTP-content">
              <Typography className="header-text">{title}</Typography>
              {content?.map((data, i) => {
                return <Typography className={"para-text"}>{data}</Typography>;
              })}
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="img-container img-end"
          >
            <img src={image} alt={image} />
          </Col>
        </Row>
      </Container>

      <ProtectionCTASection
        title_container2={title_container2}
        content_containet2={content_containet2}
      />
    </section>
  );
};

export default CommonFifthSection;
