const setMeta = (description) => {
  const metaDescription = document.querySelector('meta[name="og:description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", description);
  } else {
    const newMeta = document.createElement("meta");
    newMeta.name = "description";
    newMeta.content = description;
    document.head.appendChild(newMeta);
  }
};
export const setMetaDataForSEO = (pathname) => {
  switch (pathname) {
    case "/":
      document.title = "Home Equity Protection | EquityProtect";
      setMeta(
        "Protect your home equity with EquityProtect. Our solutions help secure your financial future by safeguarding your most valuable asset. Get expert guidance today."
      );
      break;
    case "/how-it-works":
      document.title = "How Home Equity Protection Works | EquityProtect";
      setMeta(
        "Learn how EquityProtect secures your home equity with tailored protection plans. Understand the process and safeguard your financial future with trusted solutions."
      );
      break;
    case "/pricing":
      document.title = "Home Equity Protection Pricing | EquityProtect";
      setMeta(
        "Find competitive pricing for home equity protection plans at EquityProtect. Protect your home‚Äôs value with affordable solutions designed to secure your financial future."
      );
      break;
    case "/property-risk-management":
      document.title = "Property Risk Management Services | EquityProtect";
      setMeta(
        "EquityProtect offers expert property risk management services to help you protect your home equity. Take control of your investments with our reliable risk management solutions."
      );
      break;
    case "/title-protection-real-estate-investors":
      document.title =
        "Title Protection for Real Estate Investors | EquityProtect";
      setMeta(
        "Real estate investors face increasing risks of title fraud. Guard your valuable equity with EquityProtect's patented system to protect your investments."
      );
      break;
    case "/title-protection-long-time-homeowners":
      document.title =
        "Title Protection for Long-Time Homeowners | EquityProtect";
      setMeta(
        "Home title fraud is on the rise, risking your equity. If you have significant equity in your home, safeguard it with EquityProtect's patented system."
      );
      break;
    case "/what-is-home-title-theft":
      document.title =
        "What is Home Title Theft? What is It & Ways to Protect Yourself | EquityProtect";
      setMeta(
        "Title fraud is when thieves steal your home's title to borrow against or sell it. Safeguard your home with EquityProtect's patented authentication system."
      );
      break;

    case "/for-service-provider":
      document.title = "Home Equity Service Providers | EquityProtect";
      setMeta(
        "Partner with EquityProtect as a trusted service provider. Help clients safeguard their home equity with reliable protection plans tailored to meet individual needs."
      );
      break;
    default:
      document.title = "EquityProtect";
      setMeta(
        "The First and Only True Home Title Lock - Prevents Home Stealing, Title Theft and Equity Protection"
      );
  }
};
